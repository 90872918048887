import { useQuery } from '@tanstack/react-query';
import { atom } from 'recoil';
import api from 'utils/api';

export interface ApiKey {
  id: number;
  name: string;
  isActive: boolean;
}

export const selectedApiState = atom<ApiKey | null>({
  key: 'selectedApiState',
  default: null
});

export const editApiModalOpenState = atom({
  key: 'editApiModalOpenState',
  default: false
});

export const useApiKeys = () => {
  return useQuery<ApiKey[]>({
    queryKey: ['apiKeys'],
    queryFn: async () => {
      return (await api.get(`/admin/apikey/`)).data.keys;
    },
    // avoid excessive refetch from component remount
    staleTime: 15_000
  });
};
