import { Suspense } from 'react';
import { useRecoilState } from 'recoil';

import ApisTable from './ApiTable';
import ApiKeyDetailsModal from './ApiKeyDetailsModal';

import { editApiModalOpenState } from './state';

export default function Tokens() {
  let [modal, setModal] = useRecoilState(editApiModalOpenState);

  return (
    <>
      <ApisTable />
      {modal ? (
        <Suspense fallback={<div>Loading...</div>}>
          <ApiKeyDetailsModal open={modal} onClose={() => setModal(false)} />
        </Suspense>
      ) : null}
    </>
  );
}
