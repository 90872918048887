const CONSTANTS_KEYS = [
  'ROLE',
  'STATUS',
  'ACTION',
  'LOCATION_ACRONYM_TO_FULL_FORM_MAP',
  'LOCATION_FULL_FORM_TO_ACRONYM_MAP',
  'INTERVALS',
  'DEPTH_ACRONYM_TO_FULL_FORM_MAP',
  'DEPTH_FULL_FORM_TO_ACRONYM_MAP'
] as const;

type KeyType = (typeof CONSTANTS_KEYS)[number];

export const CONSTANTS = Object.fromEntries(
  CONSTANTS_KEYS.map((v) => [v as KeyType, {}])
) as Record<KeyType, Record<string, string>>;
