import { createContext, PropsWithChildren } from 'react';

import { CONSTANTS } from 'utils/constants';
import { type ConstantsType, useConstantsProviderHook } from 'utils/hooks';

export const ConstantsContext = createContext<ConstantsType>(CONSTANTS);

export const ConstantsProvider = ({ children }: PropsWithChildren) => {
  const { CONSTANTS } = useConstantsProviderHook();
  return (
    <ConstantsContext.Provider value={CONSTANTS}>
      {children}
    </ConstantsContext.Provider>
  );
};
