import React from 'react';

import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import jwt_decode from 'jwt-decode';
import _ from 'lodash';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import Cookies from 'universal-cookie';

import TextInput from 'components/TextInput';
import { errorMessage, isTokenAvailable } from 'pages/Login/state';
import { jwtData } from 'pages/OnBoarding/state';
import { useSetParams } from 'pages/Qc/state';
import api, { fetchMesnParameters } from 'utils/api';
import { useConstants } from 'utils/hooks';

// =============================================================================
const LOGIN_API = '/public/login';

// =============================================================================
export default function Form() {
  const { CONSTANTS } = useConstants();

  const cookies = new Cookies();
  const setSnackbarMessage = useSetRecoilState(errorMessage);
  const setTokenState = useSetRecoilState(isTokenAvailable);
  const setJwtData = useSetRecoilState(jwtData);
  const [captchaDone, setCaptchaDone] = React.useState(false);
  const setPrams = useSetParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    return () => {
      formik.handleReset(null);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    onSubmit: async (values) => {
      if (!values.email || !values.password) {
        setSnackbarMessage('Fields cannot be empty!');
        return;
      }
      try {
        const res: any = await api.post(LOGIN_API, values);
        cookies.set('admin-tokens', res.data, {
          path: '/',
          secure: true
        });
        const decoded: any = jwt_decode(res.data.accessToken);
        if (
          decoded &&
          decoded.roles.length === 1 &&
          decoded.roles[0] === CONSTANTS.ROLE.ROLE_USER
        ) {
          setSnackbarMessage(
            'You do not have permission to access this website.'
          );
          return;
        }
        setTokenState(true);
        setJwtData(decoded);

        const params: any = await fetchMesnParameters();
        setPrams(params);

        navigate('/');
      } catch (err: any) {
        setSnackbarMessage('Wrong email or password!');
      }
    }
  });

  return (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '40px 0'
        }}
      >
        <TextInput
          variant='outlined'
          id='email'
          name='email'
          label='Email'
          placeholder='email@domain.com'
          value={formik.values.email}
          dataCy='email'
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          containerStyle={{ width: '100%', maxWidth: 400 }}
        />
        <TextInput
          variant='outlined'
          id='password'
          name='password'
          label='Password'
          type='password'
          placeholder='**********'
          value={formik.values.password}
          dataCy='password'
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          containerStyle={{ width: '100%', maxWidth: 400 }}
        />

        <div style={{ height: 16 }} />
        {!!import.meta.env.RECAPTCHA_SECRET_KEY && (
          <ReCAPTCHA
            sitekey={import.meta.env.RECAPTCHA_CLIENT_KEY}
            onChange={() => setCaptchaDone(true)}
          />
        )}
        <div style={{ height: 16 }} />

        <Button
          type='submit'
          variant='primary'
          color='dark'
          disabled={!captchaDone && !!import.meta.env.RECAPTCHA_SECRET_KEY}
          data-cy='login-button'
        >
          Login
        </Button>
      </form>
    </div>
  );
}
