import { useEffect, useState } from 'react';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { Button } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps
} from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import CustomContainer from 'components/CustomContainer';
import { currentRole } from 'pages/Login/state';
import { jwtData } from 'pages/OnBoarding/state';

import api from 'utils/api';
import { useConstants } from 'utils/hooks';
import useWidth from 'utils/useWidth';

import List from './list';
import Table from './Table';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

export default function OnBoarding(props: any) {
  const { CONSTANTS } = useConstants();
  const [owner, setOwner]: [any, any] = useState();
  const [credentialsData, setCredentialsData] = useState([]);
  const navigate = useNavigate();
  const width = useWidth();
  const [expanded, setExpanded]: [any, any] = useState('');
  const tokenData: any = useRecoilValue(jwtData);
  const myCurrentRole: any = useRecoilValue(currentRole);

  useEffect(() => {
    if (myCurrentRole === CONSTANTS.ROLE.ROLE_COLLABORATOR) {
      getData();
    }
  }, []);

  useEffect(() => {
    const execute = async () => {
      if (expanded === 'CREDENTIALS') {
        try {
          const response = await api.get(
            `/admin/owners/${owner.id}/box/credentials`
          );
          setCredentialsData(response.data);
        } catch (error) {
          console.log(error);
        }
      }
    };

    execute();
  }, [expanded]);

  const getData = async () => {
    try {
      const response = await api.get(`/admin/myOwner`);
      setOwner(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleDownload = async () => {
    try {
      const res: any = await api.get(`/admin/owners/${owner.id}/sdk`, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      // Create a link element and trigger a download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(res.data);
      link.download = 'mesn-buoy-guest-sdk.zip';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      alert('Success: Downloaded');
    } catch (err: any) {
      alert(`Error: ${err.response.data.message}`);
    }
  };

  const tableColumns: any = [
    {
      name: 'Box ID',
      selector: (obj: any) => obj.boxId
    },
    {
      name: 'Password',
      selector: (obj: any) => obj.password
    }
  ];

  return (
    <>
      <div style={{ height: 32 }} />
      <CustomContainer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Typography
            variant={
              ['mobile', 'tablet'].includes(width) ? 'body_large_strong' : 'h2'
            }
            color='blue.900'
          >
            Instrument Onboarding
          </Typography>
        </div>
        {owner ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <div>
              <div
                style={{
                  marginTop: 5
                }}
              >
                <Typography variant='body_regular_strong' color='green.500'>
                  Owner Id: {owner.ownerIdStr}
                </Typography>
              </div>
              <div
                style={{
                  marginTop: 5
                }}
              >
                <Typography variant='body_regular_strong' color='green.500'>
                  Owner Name: {owner.name}
                </Typography>
              </div>
            </div>
            <Button
              type='button'
              variant='secondary'
              color='dark'
              onClick={handleDownload}
            >
              Download SDK
            </Button>
          </div>
        ) : null}
        <div
          style={{
            marginTop: 10
          }}
        >
          <Accordion
            expanded={expanded === 'ON_BOARDING'}
            onChange={handleChange('ON_BOARDING')}
          >
            <AccordionSummary
              aria-controls='ON_BOARDING-content'
              id='ON_BOARDING-header'
            >
              <Typography variant='body_large_strong' color='blue.800'>
                OnBoarding
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {expanded === 'ON_BOARDING' ? (
                <List owner={owner} typePath='onboarding' type='ON_BOARDING' />
              ) : null}
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'BOX_DEPLOYMENT'}
            onChange={handleChange('BOX_DEPLOYMENT')}
          >
            <AccordionSummary
              aria-controls='BOX_DEPLOYMENT-content'
              id='BOX_DEPLOYMENT-header'
            >
              <Typography variant='body_large_strong' color='blue.800'>
                Box Deployment
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {expanded === 'BOX_DEPLOYMENT' ? (
                <List
                  owner={owner}
                  typePath='box/deployment'
                  type='BOX_DEPLOYMENT'
                />
              ) : null}
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'CONFIGURATION'}
            onChange={handleChange('CONFIGURATION')}
          >
            <AccordionSummary
              aria-controls='CONFIGURATION-content'
              id='CONFIGURATION-header'
            >
              <Typography variant='body_large_strong' color='blue.800'>
                Configuration
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {expanded === 'CONFIGURATION' ? (
                <List
                  owner={owner}
                  typePath='box/configuration'
                  type='CONFIGURATION'
                />
              ) : null}
            </AccordionDetails>
          </Accordion>
          {myCurrentRole === CONSTANTS.ROLE.ROLE_COLLABORATOR ? (
            <Accordion
              expanded={expanded === 'CREDENTIALS'}
              onChange={handleChange('CREDENTIALS')}
            >
              <AccordionSummary
                aria-controls='CREDENTIALS-content'
                id='CREDENTIALS-header'
              >
                <Typography variant='body_large_strong' color='blue.800'>
                  Credentials
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {credentialsData.length ? (
                  <Table columns={tableColumns} data={credentialsData} />
                ) : (
                  'No data found'
                )}
              </AccordionDetails>
            </Accordion>
          ) : null}
        </div>
      </CustomContainer>
    </>
  );
}
