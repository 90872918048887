import { useEffect, useState } from 'react';

import { Box, Button, IconButton } from '@mui/material';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
//import LoginSection from '../LoginSection';
import MenuItem from '@mui/material/MenuItem';
import jwt_decode from 'jwt-decode';
import { If, Then } from 'react-if';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import Cookies from 'universal-cookie';

import Select from 'components/common/Select';
import CustomLink from 'components/CustomLink';
import { currentRole, isTokenAvailable } from 'pages/Login/state';
import { jwtData } from 'pages/OnBoarding/state';
import { ReactComponent as LogoLight } from 'static/logos/Logo.svg';
import { ReactComponent as LogoDark } from 'static/logos/LogoDark.svg';
import api from 'utils/api';
import { useConstants } from 'utils/hooks';
import useWidth from 'utils/useWidth';

// =============================================================================
const nav_text_collaborator: any = ['On Boarding'];

const nav_link_collaborator: any = ['/instrument-on-boarding'];

const nav_text_admin: any = [
  'QC',
  'Cruise Data Upload',
  'Events',
  'Users',
  'Parameters',
  'Owners',
  'On Boarding',
  'Api Keys'
];
const nav_link_admin: any = [
  '/',
  '/cruise-data-upload',
  '/events',
  '/users',
  '/parameters',
  '/owners',
  '/instrument-on-boarding',
  '/apikeys'
];

const nav_text_buoy_maintainer: any = [
  'QC',
  'Cruise Data Upload',
  'Events',
  'Parameters',
  'Owners',
  'On Boarding'
];
const nav_link_buoy_maintainer: any = [
  '/',
  '/cruise-data-upload',
  '/events',
  '/parameters',
  '/owners',
  '/instrument-on-boarding'
];

// =============================================================================
const appbar_style = {
  display: 'flex',
  height: 94.5,
  alignItems: 'center'
};

const logo_style = {
  width: 116
};

// =============================================================================
interface LogoProps {
  variant: string | undefined;
  myCurrentRole: any;
}

const Logo = (props: LogoProps) => {
  const { CONSTANTS } = useConstants();

  function getLogo() {
    return <LogoLight style={logo_style} />;
  }

  return (
    <IconButton
      component={RouterLink}
      disableRipple
      to={
        props.myCurrentRole === CONSTANTS.ROLE.ROLE_ADMIN ||
        props.myCurrentRole === CONSTANTS.ROLE.ROLE_BUOY_MAINTAINER
          ? '/'
          : '/instrument-on-boarding'
      }
      key={props.myCurrentRole}
    >
      {getLogo()}
    </IconButton>
  );
};

// =============================================================================
interface AppbarProps {
  variant?: 'transparent' | 'admin' | 'default';
}

export default function Appbar(props: AppbarProps) {
  const { CONSTANTS } = useConstants();

  const width = useWidth();
  const cookies = new Cookies();
  const setTokenState = useSetRecoilState(isTokenAvailable);
  const [myCurrentRole, setCurrentRole] = useRecoilState(currentRole);
  const [currentMenu, setCurrentMenu] = useState(location.pathname);
  const tokenData: any = useRecoilValue(jwtData);
  const setJwtData = useSetRecoilState(jwtData);
  const navigate = useNavigate();

  const tokens = cookies.get('admin-tokens');
  const decoded: any = jwt_decode(tokens.accessToken);

  useEffect(() => {
    const currRole = decoded.roles.some(
      (role: any) => role === CONSTANTS.ROLE.ROLE_ADMIN
    )
      ? CONSTANTS.ROLE.ROLE_ADMIN
      : decoded.roles.filter((r: any) => r !== CONSTANTS.ROLE.ROLE_USER)[0];
    setCurrentRole(currRole);
  }, [CONSTANTS]);

  useEffect(() => {
    if (!tokenData) {
      setJwtData(decoded);
    }
  }, []);

  useEffect(() => {
    setCurrentMenu(location.pathname);
  }, [location.pathname]);

  const handleClick = async () => {
    try {
      const res = await api.post('/api/logoutUser');
      cookies.remove('admin-tokens');
      navigate('/login');
    } catch (err: any) {
      console.error(err);
    }
  };

  const token = cookies.get('admin-tokens');
  const isLoggedIn = !!token;

  function getPadding() {
    switch (width) {
      case 'tablet':
        return '0 48px';
      case 'desktop':
        return '0 96px';
      case 'mobile':
        return '0 24px';
      default:
        return '0 144px';
    }
  }

  const backgroundColor = () => {
    return 'blue.900';
  };

  const navText = () => {
    let result: any = [];
    if (tokenData) {
      if (myCurrentRole === CONSTANTS.ROLE.ROLE_COLLABORATOR) {
        result = [...result, ...nav_text_collaborator];
      }
      if (myCurrentRole === CONSTANTS.ROLE.ROLE_ADMIN) {
        result = [...result, ...nav_text_admin];
      }
      if (myCurrentRole === CONSTANTS.ROLE.ROLE_BUOY_MAINTAINER) {
        result = [...result, ...nav_text_buoy_maintainer];
      }
    }
    return result;
  };

  const navLink = () => {
    let result: any = [];
    if (tokenData) {
      if (myCurrentRole === CONSTANTS.ROLE.ROLE_COLLABORATOR) {
        result = [...result, ...nav_link_collaborator];
      }
      if (myCurrentRole === CONSTANTS.ROLE.ROLE_ADMIN) {
        result = [...result, ...nav_link_admin];
      }
      if (myCurrentRole === CONSTANTS.ROLE.ROLE_BUOY_MAINTAINER) {
        result = [...result, ...nav_link_buoy_maintainer];
      }
    }
    return result;
  };

  const handleRole = (role: any) => {
    setCurrentRole(role);
    if (role === CONSTANTS.ROLE.ROLE_COLLABORATOR) {
      navigate('/instrument-on-boarding');
    } else {
      navigate('/');
    }
  };

  const handleMenu = (menuItem: any) => {
    setCurrentMenu(menuItem);
  };

  function getMargin() {
    switch (width) {
      case 'tablet':
        return '0 0 0 16px';
      case 'laptop':
        return '0 0 0 24px';
      default:
        return '0 0 0 48px';
    }
  }

  return (
    <Box
      sx={{
        ...appbar_style,
        backgroundColor: backgroundColor(),
        padding: getPadding()
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Logo variant={props.variant} myCurrentRole={myCurrentRole} />

        <div className='app-bar menu'>
          <Select
            label='Menu'
            selected={currentMenu}
            menuProps={{
              PopoverClasses: {
                root: 'menu-list-root'
              }
            }}
            onChange={handleMenu}
            key={currentMenu}
          >
            {navText().map((text: any, index: any) => {
              const links = navLink();
              return (
                <MenuItem key={index} value={links[index]}>
                  <CustomLink
                    key={index}
                    to={links[index]}
                    variant='body_large_strong'
                    margin={getMargin()}
                  >
                    {text}
                  </CustomLink>
                </MenuItem>
              );
            })}
          </Select>
        </div>
      </div>
      <div style={{ flex: 1 }} />
      <If condition={decoded && decoded.roles.length}>
        <Then>
          <div className='app-bar'>
            <Select
              label='Current Role'
              selected={myCurrentRole}
              onChange={handleRole}
              key={myCurrentRole}
            >
              {decoded.roles
                .filter((r: any) => r !== CONSTANTS.ROLE.ROLE_USER)
                .map((role: any, i: any) => {
                  const roleStr = role.split('_').splice(1).join(' ');
                  return (
                    <MenuItem key={i} value={role}>
                      {roleStr}
                    </MenuItem>
                  );
                })}
            </Select>
          </div>
        </Then>
      </If>
      <Box sx={{ ml: 2 }}>
        <Button
          variant='primary'
          color='light'
          component={RouterLink}
          onClick={handleClick}
          to='/login'
        >
          Log out
        </Button>
      </Box>
    </Box>
  );
}
