import { useContext, useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { z } from 'zod';

import { ConstantsContext } from 'components/providers/ConstantsProvider';
import { queryClient } from 'components/providers/QueryProvider';
import { getConstants } from 'utils/api';
import { CONSTANTS as _CONSTANTS } from 'utils/constants';

export const sharedConstants = z.object({
  ROLE: z.record(z.string(), z.string()),
  STATUS: z.record(z.string(), z.string()),
  ACTION: z.record(z.string(), z.string()),
  LOCATION_ACRONYM_TO_FULL_FORM_MAP: z.record(z.string(), z.string()),
  LOCATION_FULL_FORM_TO_ACRONYM_MAP: z.record(z.string(), z.string()),
  INTERVALS: z.record(z.string(), z.string()),
  DEPTH_ACRONYM_TO_FULL_FORM_MAP: z.record(z.string(), z.string()),
  DEPTH_FULL_FORM_TO_ACRONYM_MAP: z.record(z.string(), z.string())
});

export type ConstantsType = z.infer<typeof sharedConstants>;

export const ConstantsQueryKey = ['constants'];

export const useConstantsProviderHook = () => {
  const cookies = new Cookies();
  const { pathname } = useLocation();
  const [CONSTANTS, setCONSTANTS] = useState<ConstantsType>(_CONSTANTS);
  const { data, refetch } = useQuery<ConstantsType>({
    queryKey: ConstantsQueryKey,
    queryFn: getConstants,
    refetchOnWindowFocus: 'always'
    // gcTime: 5 * 60 * 1000, // Will be GC-ed after this
    // staleTime: 5 * 60 * 1000, // Will be considered stale after this
    // refetchInterval: 60 * 1000 // Will auto refetch in bg at this interval
  });
  useEffect(() => {
    if (data) {
      setCONSTANTS(data);
    } else {
      if (pathname.endsWith('login')) {
        return;
      }
      refetch();
    }
  }, [data, pathname, refetch]);

  return {
    CONSTANTS
  };
};

export const useConstants = () => {
  const CONSTANTS = useContext(ConstantsContext);
  return { CONSTANTS };
};

// Utility Function. We only do this as a last resort when we are calling isolated functions.
// Else, we should make use of the provided `useConstants` hook to retrieve data via the Context API.
export const getConstantsFromCache = () => {
  return queryClient.getQueryData<ConstantsType>(ConstantsQueryKey);
};
