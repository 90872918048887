import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import moment from 'moment';
import { When } from 'react-if';
import { useNavigate } from 'react-router-dom';
import {
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState
} from 'recoil';
import Cookies from 'universal-cookie';

import Container from 'components/common/Container';
import MyModal from 'components/common/modal';
import CustomContainer from 'components/CustomContainer';

import { currentRole } from 'pages/Login/state';
import DataPreview from 'pages/OnBoarding/DataPreview';
import { jwtData } from 'pages/OnBoarding/state';
import UploadFile from 'pages/OnBoarding/UploadFile';

import api from 'utils/api';
import { useConstants } from 'utils/hooks';
import useWidth from 'utils/useWidth';

import Table from './Table';

const modalContainerStyle = {
  marginBottom: 48,
  marginTop: 48,
  border: '2px solid black',
  borderRadius: '20px',
  backgroundColor: 'white'
};

export default function List({
  owner,
  typePath,
  type
}: {
  owner?: any;
  typePath: string;
  type: any;
}) {
  const { CONSTANTS } = useConstants();
  const cookies = new Cookies();
  const [showFile, setShowFile] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [file, setFile]: [any, any] = React.useState(null);
  const [fileMetaData, setFileMetaData]: [any, any] = React.useState(null);
  const [pendingReviewData, setPendingReviewData]: [any, any] = React.useState(
    []
  );
  const [approvedData, setApprovedData]: [any, any] = React.useState([]);
  const [commentsData, setCommentsData]: [any, any] = React.useState([]);
  const [rejectedData, setRejectedData]: [any, any] = React.useState([]);
  const [reason, setReason] = React.useState('');
  const [showReason, setShowReason] = React.useState(false);
  const [isNewUpload, setIsNewUpload] = React.useState(false);
  const tokenData: any = useRecoilValue(jwtData);
  const myCurrentRole: any = useRecoilValue(currentRole);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setShowFile(false);
    setFile(null);
    setIsNewUpload(false);
    setFileMetaData(null);
    setCommentsData([]);
  };

  const { roles } = tokenData;

  const TabPanel = (props: any) => {
    const { children, value, index, ...other } = props;

    return (
      <div hidden={value !== index} {...other}>
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  };

  const tableColumns: any = [
    {
      name: 'File Name',
      selector: (obj: any) => obj.fileName
    },
    {
      name: 'Action',
      selector: (obj: any) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Button
            type='button'
            variant='secondary'
            color='dark'
            data-cy='view-onboarding-button'
            onClick={() => {
              handleView({
                s3FileId: obj.s3FileId,
                id: obj.id
              });
            }}
          >
            View
          </Button>
        </div>
      )
    }
  ];

  const getData = async () => {
    try {
      const [penRes, appRes, rejRes] = await Promise.all([
        api.get(
          `/admin/owners${owner ? `/${owner.id}` : ''}/${typePath}/${
            CONSTANTS.STATUS.PENDING_REVIEW
          }`
        ),
        api.get(
          `/admin/owners${owner ? `/${owner.id}` : ''}/${typePath}/${
            CONSTANTS.STATUS.APPROVED
          }`
        ),
        api.get(
          `/admin/owners${owner ? `/${owner.id}` : ''}/${typePath}/${
            CONSTANTS.STATUS.REJECTED
          }`
        )
      ]);

      setPendingReviewData(penRes.data);
      setApprovedData(appRes.data);
      setRejectedData(rejRes.data);
    } catch (error) {
      console.log(error);
      alert('Error: Something went wrong');
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  const handleView = async (obj: any) => {
    try {
      const [s3Res, commentsRes] = await Promise.all([
        api.post('/admin/getS3Url', {
          fileId: obj.s3FileId,
          reference: `${type}S`,
          ...(owner && { ownerId: String(owner.id) })
        }),
        api.get(
          `/admin/owners${owner ? `/${owner.id}` : ''}/${typePath}/${
            obj.id
          }/comments`
        )
      ]);
      setCommentsData(commentsRes.data);
      const url =
        import.meta.env.MODE === 'development'
          ? s3Res.data.url.replace('host.docker.internal', 'localhost')
          : s3Res.data.url;
      let fileRes, fileData;
      if (type === 'ON_BOARDING') {
        fileRes = await axios.get(url, {
          responseType: 'arraybuffer'
        });
        fileData = fileRes.data;
      } else {
        fileRes = await axios.get(url);
        fileData = JSON.stringify(fileRes.data);
      }
      setFile(fileData);
      setFileMetaData({
        status: value,
        id: obj.id
      });
      setShowFile(true);
    } catch (err: any) {
      alert(`Error: ${err.response.data.message}`);
    }
  };

  const handleApprove = async () => {
    try {
      await api.post(`/admin/owners/${typePath}/${fileMetaData.id}/APPROVE`);
      handleClose();
      getData();
      alert('Success: Approved');
    } catch (err: any) {
      // setSnackbarMessage(err.message);
    }
  };

  const handleReject = async () => {
    try {
      if (!reason) {
        alert('Error: Reason for Rejection is required');
        return;
      }
      await api.post(`/admin/owners/${typePath}/${fileMetaData.id}/REJECT`, {
        comment: reason
      });
      setShowReason(false);
      setReason('');
      handleClose();
      getData();
      alert('Success: Rejected');
    } catch (err: any) {
      // setSnackbarMessage(err.message);
    }
  };

  const handleSubmitForApproval = async () => {
    const fd = new FormData();
    fd.append('file', file);
    try {
      await api.post(`/admin/owners/${owner.id}/${typePath}`, fd, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      handleClose();
      getData();
      alert('Successfully uploaded');
    } catch (err: any) {
      // setSnackbarMessage(err.message);
    }
  };

  const handleReSubmitForApproval = async () => {
    const fd = new FormData();
    fd.append('file', file);
    try {
      await api.post(
        `/admin/owners/${owner.id}/${typePath}/${fileMetaData.id}`,
        fd,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      handleClose();
      getData();
      alert('Success: updated');
    } catch (err: any) {
      // setSnackbarMessage(err.message);
    }
  };

  const handleReason = (e: any) => {
    setReason(e.target.value);
  };

  const getModalButtons = () => {
    if (
      myCurrentRole === CONSTANTS.ROLE.ROLE_ADMIN ||
      myCurrentRole === CONSTANTS.ROLE.ROLE_BUOY_MAINTAINER
    ) {
      if (fileMetaData && fileMetaData.status == 0) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginRight: '35px'
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingTop: 10,
                paddingRight: 10
              }}
            >
              <div />
              <Button
                type='button'
                variant='secondary'
                color='dark'
                onClick={() => {
                  setShowReason(true);
                }}
                style={{ marginRight: 15 }}
              >
                Reject
              </Button>
              <Button
                type='button'
                variant='primary'
                color='dark'
                onClick={handleApprove}
              >
                Approve
              </Button>
            </div>
          </div>
        );
      }
    }

    if (myCurrentRole === CONSTANTS.ROLE.ROLE_COLLABORATOR) {
      if (!fileMetaData) {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingTop: 10,
              paddingRight: 40
            }}
          >
            <Button
              type='submit'
              variant='primary'
              color='dark'
              onClick={handleSubmitForApproval}
            >
              Submit for Approval
            </Button>
          </div>
        );
      } else if (fileMetaData && fileMetaData.status == 2) {
        if (isNewUpload) {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingTop: 10,
                paddingRight: 40
              }}
            >
              <Button
                type='submit'
                variant='primary'
                color='dark'
                onClick={handleReSubmitForApproval}
              >
                Submit for Approval
              </Button>
            </div>
          );
        }
        // return (
        //   <div
        //     style={{
        //       display: 'flex',
        //       justifyContent: 'flex-end',
        //       paddingTop: 10,
        //       paddingRight: 40
        //     }}
        //   >
        //     <UploadFile
        //       accept={
        //         type === 'ON_BOARDING' ? 'application/zip' : 'application/json'
        //       }
        //       title='Re-upload'
        //       handleChange={async (e: any) => {
        //         const uploadedFile = e.target.files ? e.target.files[0] : null;
        //         try {
        //           const fd = new FormData();
        //           fd.append('file', uploadedFile);

        //           await api.post(
        //             `/admin/owners/${owner.id}/${typePath}/validate`,
        //             fd,
        //             {
        //               headers: {
        //                 'Content-Type': 'multipart/form-data'
        //               }
        //             }
        //           );
        //           setFile(uploadedFile);
        //           if (e.target.files) {
        //             setIsNewUpload(true);
        //             setShowFile(true);
        //           }
        //         } catch (err: any) {
        //           alert(`Error: ${err.response.data.message}`);
        //         }
        //       }}
        //     />
        //   </div>
        // );
      }
    }
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        {myCurrentRole === CONSTANTS.ROLE.ROLE_COLLABORATOR && (
          <UploadFile
            accept={
              type === 'ON_BOARDING' ? 'application/zip' : 'application/json'
            }
            handleChange={async (e: any) => {
              const uploadedFile = e.target.files ? e.target.files[0] : null;
              try {
                const fd = new FormData();
                fd.append('file', uploadedFile);

                await api.post(
                  `/admin/owners/${owner.id}/${typePath}/validate`,
                  fd,
                  {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }
                  }
                );

                setFile(uploadedFile);
                if (e.target.files) {
                  setIsNewUpload(true);
                  setShowFile(true);
                }
              } catch (err: any) {
                alert(`Error: ${err.response.data.message}`);
              }
            }}
          />
        )}
      </div>

      <MyModal open={showFile} onClose={handleClose}>
        <Container style={modalContainerStyle}>
          {getModalButtons()}

          <div style={{ height: 16 }} />
          <DataPreview
            type={type}
            file={file}
            comments={commentsData}
            status={fileMetaData ? fileMetaData.status : null}
          />
        </Container>
      </MyModal>
      <Modal open={showReason}>
        <div
          style={{
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            margin: 300,
            padding: 20
          }}
        >
          <TextField
            variant='outlined'
            id='reason'
            name='reason'
            label='Reason for Rejection'
            placeholder='Please enter the reason for rejection'
            value={reason}
            onChange={handleReason}
            multiline
            rows={6}
          />
          <div
            style={{
              display: 'flex',
              margin: 20
            }}
          >
            <Button
              type='button'
              variant='secondary'
              color='dark'
              onClick={() => {
                setReason('');
                setShowReason(false);
              }}
              style={{ marginRight: 15 }}
            >
              Close
            </Button>
            <Button
              type='button'
              variant='primary'
              color='dark'
              onClick={handleReject}
            >
              Submit Rejection
            </Button>
          </div>
        </div>
      </Modal>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab
              label={`${
                myCurrentRole === CONSTANTS.ROLE.ROLE_COLLABORATOR
                  ? 'Submitted'
                  : myCurrentRole === CONSTANTS.ROLE.ROLE_ADMIN ||
                      myCurrentRole === CONSTANTS.ROLE.ROLE_BUOY_MAINTAINER
                    ? 'Needs Approval'
                    : ''
              }`}
            />
            <Tab label='Approved' />
            <Tab label='Rejected' />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          {pendingReviewData.length > 0 ? (
            <Table
              columns={[
                ...tableColumns,
                {
                  name: 'Submitted At',
                  selector: (obj: any) =>
                    moment(Number(obj.createdAt)).format('YYYY-MM-DD HH:mm A')
                }
              ]}
              data={pendingReviewData}
            />
          ) : (
            'No data found'
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {approvedData.length > 0 ? (
            <Table
              columns={[
                ...tableColumns,
                {
                  name: 'Approved At',
                  selector: (obj: any) =>
                    moment(Number(obj.createdAt)).format('YYYY-MM-DD HH:mm A')
                }
              ]}
              data={approvedData}
            />
          ) : (
            'No data found'
          )}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {rejectedData.length > 0 ? (
            <Table
              columns={[
                ...tableColumns,
                {
                  name: 'Rejected At',
                  selector: (obj: any) =>
                    moment(Number(obj.createdAt)).format('YYYY-MM-DD HH:mm A')
                }
              ]}
              data={rejectedData}
            />
          ) : (
            'No data found'
          )}
        </TabPanel>
      </Box>
    </div>
  );
}
