import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import {
  Alert,
  Button,
  Container as MuiContainer,
  Select,
  Snackbar,
  Typography,
  TextField,
  MenuItem
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { useFormik } from 'formik';
import moment from 'moment';
import Cookies from 'universal-cookie';

import Container from 'components/common/Container';
import TextInput from 'components/common/TextInput';
import api from 'utils/api';
import { useConstants } from 'utils/hooks';

const EVENTS_API = '/admin/events';
const cookies = new Cookies();

// =============================================================================
export default function EditEventTableModal(props: any) {
  const { CONSTANTS } = useConstants();
  const [snackbar, setSnackbar] = useState('');
  const [startTime, setStartTime] = React.useState<Dayjs | null>(
    dayjs(Number(props.rowData.startTs))
  );
  const [endTime, setEndTime] = React.useState<Dayjs | null>(
    dayjs(Number(props.rowData.endTs))
  );
  const { handleRefreshTable } = props;

  const locationsList = useMemo(() => {
    return Object.entries(CONSTANTS.LOCATION_ACRONYM_TO_FULL_FORM_MAP).map(
      ([k, v]) => ({ code: k, label: v })
    );
  }, [CONSTANTS]);
  // =============================================================================
  /*Converts form values into values stored in Events API 
  Parameters:
  values: Stored Formik field values
  length: maxId in events
  */
  function convert(values: any) {
    const output = {
      comment: values.comments,
      endTs: convertTime(endTime),
      location: values.location,
      name: values.event,
      startTs: convertTime(startTime),
      id: props.rowData.id
    };
    return JSON.parse(JSON.stringify(output));
  }

  function convertTime(dateTime: Dayjs | null) {
    const dateTimeStr = dateTime?.toString();
    const formattedDateTime = moment(dateTimeStr).valueOf();
    return formattedDateTime;
  }

  const formik: any = useFormik({
    //Set initial values to props data so users can just edit as per needed.
    initialValues: {
      location: props.rowData.location,
      event: props.rowData.name,
      loggedBy: props.rowData.createdByName,
      comments: props.rowData.comment
    },
    onSubmit: async (values) => {
      if (!values.location || !values.event) {
        setSnackbar('Fields cannot be empty!');
        return;
      }
      if (!startTime || !endTime) {
        setSnackbar('Invalid start or end time');
        return;
      }
      if (startTime && endTime && endTime.diff(startTime) <= 0) {
        setSnackbar('End time must be later than start time.');
        return;
      }
      const postValues = convert(values);
      try {
        await api.patch(EVENTS_API + '/' + props.rowData.id, postValues, {
          headers: {
            Authorization: 'Bearer ' + cookies.get('admin-tokens').accessToken,
            'Content-Type': 'application/json'
          }
        });
        handleRefreshTable();
        handleClose();
      } catch (err: any) {
        setSnackbar('Server error, please try again later!');
      }
    }
  });

  const handleClose = () => {
    props.handleCloseEditModal();
  };

  const modalContainerStyle = {
    marginBottom: 48,
    marginTop: 48,
    border: '2px solid black',
    borderRadius: '20px',
    backgroundColor: 'white'
  };

  // =============================================================================
  return (
    <div
      style={{
        maxWidth: '100%',
        alignContent: 'center',
        border: 20,
        margin: 'auto'
      }}
    >
      <Container style={modalContainerStyle}>
        <div style={{ height: 40 }} />
        <Typography variant='h2' color='blue.900' align='center'>
          Update Event
        </Typography>
        <form
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '40px 0'
          }}
        >
          <>
            <div
              style={{
                marginBottom: 32,
                width: '100%',
                maxWidth: 400
              }}
            >
              <DateTimePicker
                className='date-time-picker'
                renderInput={(props) => <TextField {...props} />}
                label='Start Time'
                value={startTime}
                onChange={(newValue: any) => {
                  if (!newValue.isValid()) {
                    setStartTime(null);
                    return;
                  }
                  setStartTime(newValue);
                }}
              />
            </div>
            <div
              style={{
                marginBottom: 32,
                width: '100%',
                maxWidth: 400
              }}
            >
              <DateTimePicker
                className='date-time-picker'
                renderInput={(props) => <TextField {...props} />}
                label='End Time'
                value={endTime}
                onChange={(newValue: any) => {
                  if (!newValue.isValid()) {
                    setEndTime(null);
                    return;
                  }
                  setEndTime(newValue);
                }}
              />
            </div>
            <div
              style={{
                width: '100%',
                maxWidth: 400,
                marginBottom: 16,
                display: 'flex',
                flexDirection: 'column',
                gap: 6
              }}
            >
              <span
                style={{
                  fontWeight: 'bold'
                }}
              >
                Location
              </span>
              <Select
                variant='outlined'
                id='location'
                name='location'
                placeholder=''
                value={formik.values.location}
                onChange={formik.handleChange}
                error={
                  formik.touched.location && Boolean(formik.errors.location)
                }
              >
                {(locationsList ?? [{ code: 'SJI', label: 'St Johns' }]).map(
                  ({ code, label }) => (
                    <MenuItem key={code} value={code}>
                      {label}
                    </MenuItem>
                  )
                )}
              </Select>
            </div>
            <TextInput
              variant='outlined'
              id='event'
              name='event'
              label='Event'
              placeholder=''
              value={formik.values.event}
              onChange={formik.handleChange}
              error={formik.touched.event && Boolean(formik.errors.event)}
              helperText={formik.touched.event && formik.errors.event}
              labelStyle={{ marginLeft: 0, marginBottom: 6 }}
              containerStyle={{
                width: '100%',
                maxWidth: 400,
                display: 'flex',
                flexDirection: 'column'
              }}
              sx={{
                borderRadius: 4,
                '& .MuiOutlinedInput-root': {
                  borderRadius: 4
                }
              }}
            />
            <TextInput
              variant='outlined'
              id='loggedBy'
              name='loggedBy'
              label='Logged By'
              placeholder=''
              disabled={true}
              value={formik.values.loggedBy}
              onChange={formik.handleChange}
              error={formik.touched.loggedBy && Boolean(formik.errors.loggedBy)}
              helperText={formik.touched.loggedBy && formik.errors.loggedBy}
              labelStyle={{ marginLeft: 0, marginBottom: 6 }}
              containerStyle={{
                width: '100%',
                maxWidth: 400,
                display: 'flex',
                flexDirection: 'column'
              }}
              sx={{
                borderRadius: 4,
                '& .MuiOutlinedInput-root': {
                  borderRadius: 4
                }
              }}
            />
            <TextField
              variant='outlined'
              id='comments'
              name='comments'
              label='Additional Comments'
              placeholder='Optional'
              value={formik.values.comments}
              onChange={formik.handleChange}
              error={formik.touched.comments && Boolean(formik.errors.comments)}
              helperText={formik.touched.comments && formik.errors.comments}
              style={{
                width: '100%',
                maxWidth: 400,
                marginBottom: 32
              }}
              multiline
              rows={6}
            />
            {/* <div style={{ height: 16 }} /> */}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                type='button'
                variant='secondary'
                color='dark'
                onClick={handleClose}
                style={{ marginRight: 10 }}
              >
                Close
              </Button>
              <Button
                type='button'
                variant='primary'
                color='dark'
                onClick={() => formik.handleSubmit(formik.values)}
              >
                Save Changes
              </Button>
            </div>
            <div style={{ height: 40 }} />
          </>
        </form>
      </Container>
      <Snackbar
        open={!!snackbar}
        autoHideDuration={3000}
        onClose={() => setSnackbar('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          sx={{
            width: '100%',
            maxWidth: 300
          }}
          onClose={() => setSnackbar('')}
          variant={'filled'}
          severity={'error'}
        >
          {snackbar}
        </Alert>
      </Snackbar>
    </div>
  );
}
