import { useEffect, useState } from 'react';

import { Button, Dialog, Typography } from '@mui/material';

import TextInput from 'components/TextInput';
import api from 'utils/api';
import { useConstants } from 'utils/hooks';
import { CreateOwnerSchema, UpdateOwnerSchema } from 'utils/types';
import Table from './Table';

const marginSize = 48;

const modalContainerStyle = {
  margin: `${marginSize}px`,
  backgroundColor: 'white',
  width: 800
};

const OwnerDetailsModal = ({
  open,
  onClose,
  owner,
  action,
  handleAction
}: {
  open: any;
  onClose: any;
  owner: any;
  action: any;
  handleAction: any;
}) => {
  const { CONSTANTS } = useConstants();
  const isCreate = action === 'CREATE';
  const [data, setData] = useState(
    isCreate
      ? {
          ownerIdStr: '',
          name: '',
          admins: [],
          users: []
        }
      : { ...owner }
  );
  const [currAdminEmail, setCurrAdminEmail] = useState('');
  const [currUserEmail, setCurrUserEmail] = useState('');

  const adminColumns: any = [
    {
      name: 'Admin Email',
      selector: (admin: any) => admin.email
    },
    {
      name: 'Admin Name',
      selector: (admin: any) => admin.name
    },
    {
      name: 'Action',
      selector: (admin: any) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Button
            type='button'
            variant='secondary'
            color='dark'
            data-cy='remove-admin-button'
            onClick={() => {
              const newData = {
                ...data,
                admins: data.admins.filter((obj: any) => obj.id !== admin.id)
              };
              setData(newData);
            }}
          >
            Remove
          </Button>
        </div>
      )
    }
  ];

  const userColumns: any = [
    {
      name: 'User Email',
      selector: (user: any) => user.email
    },
    {
      name: 'User Name',
      selector: (user: any) => user.name
    },
    {
      name: 'Action',
      selector: (user: any) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Button
            type='button'
            variant='secondary'
            color='dark'
            data-cy='remove-user-button'
            onClick={() => {
              const newData = {
                ...data,
                users: data.users.filter((obj: any) => obj.id !== user.id)
              };
              setData(newData);
            }}
          >
            Remove
          </Button>
        </div>
      )
    }
  ];

  const handleGetAdmin = async () => {
    if (!currAdminEmail.length) {
      alert('Please enter Admin Email');
      return;
    }
    if (data.admins.some((admin: any) => admin.email === currAdminEmail)) {
      alert('Admin Email already added');
      return;
    }
    try {
      const adminRes = await api.get(`/admin/users/byEmail/${currAdminEmail}`);
      const adminData = adminRes.data;
      if (
        !adminData.roles.some(
          (role: any) =>
            role == CONSTANTS.ROLE.ROLE_ADMIN ||
            role == CONSTANTS.ROLE.ROLE_BUOY_MAINTAINER
        )
      ) {
        alert('The user is not an admin/buoy maintainer.');
        return;
      }
      const newAdmins: any = [...data.admins];
      newAdmins.unshift({
        id: adminData.id,
        email: adminData.email,
        name: adminData.name
      });
      setData({
        ...data,
        admins: newAdmins
      });
      setCurrAdminEmail('');
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        alert('Admin Email does not exist in the database.');
      } else if (error.response && error.response.status === 400) {
        alert('Please check that the Admin Email is in the correct format.');
      } else {
        alert(error.response.data.message);
      }
    }
  };

  const handleGetUser = async () => {
    if (!currUserEmail.length) {
      alert('Please enter Collaborator Email');
      return;
    }
    if (data.users.some((user: any) => user.email === currUserEmail)) {
      alert('Collaborator Email already added');
      return;
    }
    try {
      const userRes = await api.get(`/admin/users/byEmail/${currUserEmail}`);
      const userData = userRes.data;
      if (
        !userData.roles.some(
          (role: any) => role == CONSTANTS.ROLE.ROLE_COLLABORATOR
        )
      ) {
        alert('The user is not a Collaborator.');
        return;
      }
      const newUsers: any = [...data.users];
      newUsers.unshift({
        id: userData.id,
        email: userData.email,
        name: userData.name
      });
      setData({
        ...data,
        users: newUsers
      });
      setCurrUserEmail('');
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        alert('Collaborator Email does not exist in the database.');
      } else if (error.response && error.response.status === 400) {
        alert(
          'Please check that the Collaborator Email is in the correct format.'
        );
      } else {
        alert(error.response.data.message);
      }
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <div style={modalContainerStyle} className='owner-details'>
          <Typography
            variant='h2'
            color='blue.900'
            style={{ marginBottom: 15 }}
          >
            {isCreate ? 'Create Owner' : 'Owner Details'}:
          </Typography>
          <TextInput
            variant='outlined'
            id='ownerIdStr'
            name='ownerIdStr'
            label='Owner Id'
            dataCy='ownerIdStr'
            value={data.ownerIdStr}
            onChange={(e: any) => {
              setData({ ...data, ownerIdStr: e.target.value });
            }}
            containerStyle={{ width: '100%', maxWidth: 400 }}
          />
          <TextInput
            variant='outlined'
            id='name'
            name='name'
            label='Owner Name'
            dataCy='ownerName'
            value={data.name}
            onChange={(e: any) => {
              setData({ ...data, name: e.target.value });
            }}
            containerStyle={{ width: '100%', maxWidth: 400 }}
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <TextInput
              variant='outlined'
              id='admin'
              name='admin'
              label='Admins'
              dataCy='adminEmail'
              value={currAdminEmail}
              placeholder='email@domain.com'
              containerStyle={{ width: '100%', maxWidth: 400 }}
              onChange={(e: any) => {
                setCurrAdminEmail(e.target.value);
              }}
            />
            <Button
              type='button'
              variant='primary'
              color='dark'
              onClick={handleGetAdmin}
              data-cy='add-admin-button'
              style={{
                marginLeft: 20
              }}
            >
              Add admin
            </Button>
          </div>
          {data.admins.length ? (
            <Table data={data.admins} columns={adminColumns} />
          ) : null}
          <div
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <TextInput
              variant='outlined'
              id='buoyUser'
              name='buoyUser'
              label='Collaborators'
              value={currUserEmail}
              dataCy='userEmail'
              placeholder='email@domain.com'
              containerStyle={{ width: '100%', maxWidth: 400 }}
              onChange={(e: any) => {
                setCurrUserEmail(e.target.value);
              }}
            />
            <Button
              type='button'
              variant='primary'
              color='dark'
              onClick={handleGetUser}
              data-cy='add-collaborator-button'
              style={{
                marginLeft: 20
              }}
            >
              Add Collaborator
            </Button>
          </div>
          {data.users.length ? (
            <Table data={data.users} columns={userColumns} />
          ) : null}
          <div
            style={{
              display: 'flex',
              margin: 20
            }}
          >
            <Button
              type='button'
              variant='secondary'
              color='dark'
              data-cy='close-button'
              onClick={() => {
                onClose();
              }}
              style={{ marginRight: 15 }}
            >
              Close
            </Button>
            <Button
              type='button'
              variant='primary'
              color='dark'
              data-cy='action-button'
              onClick={() => {
                const input = {
                  ...data,
                  admins: data.admins.map((admin: any) => admin.id),
                  users: data.users.map((user: any) => user.id)
                };
                let validation: any;
                if (action === 'CREATE') {
                  validation = CreateOwnerSchema.safeParse(input);
                } else {
                  validation = UpdateOwnerSchema.safeParse(input);
                }
                if (!validation.success) {
                  alert(validation.error.issues[0].message);
                  return;
                }
                handleAction(validation.data);
              }}
            >
              {isCreate ? 'Create Owner' : 'Update Owner'}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default OwnerDetailsModal;
