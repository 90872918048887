import { useRecoilValue } from 'recoil';

import { currentRole } from 'pages/Login/state';
import OnBoarding from 'pages/OnBoarding/Index';
import { jwtData } from 'pages/OnBoarding/state';
import Qc from 'pages/Qc/Index';

import { useConstants } from 'utils/hooks';

export default function Home() {
  const { CONSTANTS } = useConstants();
  const myCurrentRole: any = useRecoilValue(currentRole);

  if (myCurrentRole === CONSTANTS.ROLE.ROLE_COLLABORATOR) {
    return myCurrentRole ? <OnBoarding /> : null;
  }
  return <Qc />;
}
