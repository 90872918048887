import SetCard from './SetCard';
import AddSetButton from './AddSetButton';
import SensorDetails from './SensorDetails';
import GetDataButton from './GetDataButton';
import { useRecoilValue } from 'recoil';
import { cardSetAtom } from 'pages/Qc/state';

// =============================================================================
const APPBAR_HEIGHT = 40 + 94.5;
const SIDEBAR_HEIGHT = window.innerHeight - APPBAR_HEIGHT;

// =============================================================================
const side_drawer_wrapper_style = {
  height: '100%',
  width: '100%',
  boxShadow: '8px 0px 16px rgba(0, 0, 0, 0.16)',
  display: 'flex',
  flexDirection: 'column' as const,
  flexFlow: 'column',
  paddingBottom: 20
};

const card_wrapper_style = {
  display: 'flex',
  flexDirection: 'column' as const,
  marginBottom: 8,
  overflowY: 'scroll' as const,
  overflowX: 'hidden' as const
};

// =============================================================================
export default function SideDrawer() {
  const cardSet: any = useRecoilValue(cardSetAtom);

  return (
    <div style={side_drawer_wrapper_style}>
      <div style={card_wrapper_style}>
        {generateSetCard()}
        <div style={{ height: 8 }} />
      </div>
      {/* <div style={{flex: 1}}/> */}
      <div style={{ padding: '0 16px' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around'
          }}
        >
          <AddSetButton />
          <GetDataButton />
        </div>
        <div style={{ height: 8 }} />
        <SensorDetails />
      </div>
    </div>
  );

  function generateSetCard() {
    return cardSet.map((item: any, index: any) => (
      <SetCard
        key={index}
        index={index}
        location={item.location}
        depth={item.depth}
        depths={item.depths}
        parameter={item.parameterDesc}
        isCruiseParam={item.isCruiseParam}
        isDcps={item.sensorId === 'dcps'}
      />
    ));
  }
}

// =============================================================================
