import { useRef, useEffect } from 'react';
import JSZip from 'jszip';
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';
import {
  activeGraphAtom,
  isLoadingAtom,
  graphDataAtom,
  startQcAtom,
  endQcAtom,
  startAtom,
  endAtom,
  boxDataAtom
} from '../pages/Qc/state';
import { useCallback } from 'react';
import { queryLineData, queryBoxData } from 'utils/api';
import momentTZ from 'moment-timezone';

export const getAppVersion = () => {
  const now = new Date();
  const month = now.getMonth() + 1;
  const date = now.getDate();
  const dateStr = `${now.getFullYear()}${month < 10 ? '0' + month : month}${
    date < 10 ? '0' + date : date
  }`;
  const BUILD_NUM = import.meta.env.VITE_BUILD_NUM || '';

  return BUILD_NUM ? ` v1.0-(${dateStr}-${BUILD_NUM})` : '';
};

export const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const unzip = async (zipFile: any) => {
  if (zipFile && zipFile.size !== 0) {
    // Create a new zip file instance
    const unpackedZip = new JSZip();
    // Load the file into the zip file
    await unpackedZip.loadAsync(zipFile);
    return unpackedZip;
  }

  return {};
};

export const extractOnBoardingFile = async (unpackedZip: any) => {
  const res: any = {
    onBoarding: null,
    others: []
  };

  if (unpackedZip && unpackedZip.files) {
    let zipEntry: any;
    for (zipEntry in unpackedZip.files) {
      const entry = unpackedZip.files[zipEntry];
      const filename = entry.name.toLowerCase();
      if (
        filename.includes('onboarding.') &&
        filename.includes('.json') &&
        !filename.includes('__MACOSX/'.toLowerCase())
      ) {
        const jsonContent = await entry.async('string');

        // parse the JSON content
        const jsonData = JSON.parse(jsonContent);
        res.onBoarding = jsonData;
      } else if (
        !filename.includes('__MACOSX/'.toLowerCase()) &&
        filename.includes('.json')
      ) {
        const jsonContent = await entry.async('string');

        // parse the JSON content
        const jsonData = JSON.parse(jsonContent);
        res.others.push({ name: entry.name, data: jsonData });
      }
    }
  }
  return res;
};

export const useFetchGraphData = () => {
  const setGraphData = useSetRecoilState(graphDataAtom);
  const setBoxData = useSetRecoilState(boxDataAtom);
  const setStartQc = useSetRecoilState(startQcAtom);
  const setEndQc = useSetRecoilState(endQcAtom);
  const setIsLoading = useSetRecoilState(isLoadingAtom);
  const setActiveGraph = useSetRecoilState(activeGraphAtom);

  const fetchGraphData = useCallback(
    async (startTime: number, endTime: number, cardSet: any[]) => {
      let fetchedInitialData = false;
      try {
        setActiveGraph('Line Chart');
        setGraphData([]);
        setBoxData([]);
        setIsLoading(true);

        const newData = await cardSet.reduce(
          async (tempData: any, card: any) => {
            const res = await queryLineData(startTime, endTime, card);
            const temp = await tempData;
            temp.push(res);
            return temp;
          },
          []
        );

        setStartQc({
          i: null,
          ts: null
        });
        setEndQc({
          i: null,
          ts: null
        });
        setGraphData(newData);
        setIsLoading(false);
      } catch (error: any) {
        console.error('Error fetching graph data:', error);
        alert(error.response.data.message);
      } finally {
        setIsLoading(false);
        fetchedInitialData = true;
      }

      if (fetchedInitialData) {
        try {
          const intervals = getNearestBinSizesForBoxPlot(startTime, endTime);

          const newData = await cardSet.reduce(
            async (tempData: any, card: any) => {
              let format: any;
              const cardBoxData: any = {};
              for (const interval of intervals) {
                const res: any = await queryBoxData(
                  startTime,
                  endTime,
                  card,
                  interval
                );

                if (interval === '1W') {
                  format = 'DD MMM YYYY';
                } else if (interval === '1MO') {
                  format = 'MMM YYYY';
                } else {
                  format = 'DD MMM YYYY';
                }
                cardBoxData[interval] = {
                  ...res,
                  data: res.data.map((obj: any) => {
                    if (obj.qcFlag == 4) {
                      return null;
                    }
                    const min = Number(obj.min);
                    const max = Number(obj.max);
                    return {
                      min,
                      max,
                      median: Number(obj.median),
                      q1: Number(obj.q1),
                      q3: Number(obj.q3),
                      timestamp: Number(obj.timestamp),
                      whiskerMin: min,
                      whiskerMax: max
                    };
                  }),
                  labels: res.data.map((obj: any) => {
                    return momentTZ
                      .utc(Number(obj.timestamp))
                      .tz('Asia/Singapore')
                      .format(format);
                  })
                };
              }
              const temp = await tempData;
              temp.push(cardBoxData);
              return temp;
            },
            []
          );
          setBoxData(newData);
        } catch (error) {
          console.error('Error fetching box data:', error);
        }
      }
    },
    []
  );

  return { fetchGraphData };
};

export const getNearestBinSizesForBoxPlot = (
  fromTimestamp: number,
  toTimestamp: number
): any => {
  const millisecondsInDay = 24 * 60 * 60 * 1000;
  const durationInDays = (toTimestamp - fromTimestamp) / millisecondsInDay;

  let intervals;

  if (durationInDays <= 30) {
    intervals = ['1D', '1W', '1MO'];
  } else if (durationInDays > 30 && durationInDays <= 210) {
    intervals = ['1W', '1MO'];
  } else {
    intervals = ['1MO'];
  }

  return intervals;
};
