import {
  Dialog,
  Button,
  Typography,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import {
  useState,
  ChangeEvent,
  ReactNode,
  HTMLAttributes,
  MouseEventHandler,
  useEffect
} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import useViewportRatio from 'utils/useViewportRatio';
import _ from 'lodash';
import { useRecoilState } from 'recoil';
import { selectedApiState, useApiKeys } from './state';

import api from 'utils/api';
import { useQuery } from '@tanstack/react-query';
import { useConstants } from 'utils/hooks';

interface ApiKeyDetailsModalProps {
  open: boolean;
  onClose: () => void;
}

type Parameter = {
  id: string;
  parameterName: string;
  category: string;
  parameterDesc: string;
  unit: string;
  isFree: boolean;
  location: string;
  isMonthlyAvg: boolean;
  noOfDecimalPlaces: number;
  depth: string[];
};

type StructuredParamsList = {
  [location: string]: {
    LIVE: { [category: string]: Parameter[] };
    MONTHLY: { [category: string]: Parameter[] };
    CRUISE: { [category: string]: Parameter[] };
  };
};

const marginSize = 48;

const modalContainerStyle = {
  margin: `${marginSize}px`,
  backgroundColor: 'white'
};

const PARAMETER_TYPES = [
  'WATER_PROPERTIES',
  'WATER_CONTENTS',
  'AIR_PROPERTIES',
  'MONTHLY_CRUISE_PARAMETERS'
];

type TabPanelProps = {
  children: ReactNode;
  value: number;
  setDirection?: boolean;
  index: number;
} & HTMLAttributes<HTMLDivElement>;

const TabPanel = (props: TabPanelProps) => {
  const { children, value, setDirection, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {value === index && (
        <Box
          sx={{
            p: 3,
            display: 'flex',
            ...(setDirection && { flexDirection: 'column' })
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
};

const ApiKeyDetailsModal: React.FC<ApiKeyDetailsModalProps> = ({
  open,
  onClose
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { CONSTANTS } = useConstants();

  const {
    data: allParam,
    isLoading: paramLoading,
    error: paramError
  } = useQuery<Parameter[]>({
    queryKey: ['allParams'],
    queryFn: async () => (await api.get('/public/parameters')).data,
    // This doesn't really ever change, however just
    // in case there is a change, put it as 5 min
    staleTime: 300_000
  });
  const [selectedApiKey, setSelectedApiKey] = useRecoilState(selectedApiState);

  const { refetch } = useApiKeys();

  const {
    data: initialPermissionData,
    isLoading: permIsLoading,
    error: permError
  } = useQuery<{ parameterId: string }[]>({
    queryKey: ['apiKeyPermission', selectedApiKey?.id],
    queryFn: async () =>
      (
        await api.get('/admin/apikey/permissions', {
          params: {
            // Bang instead of ? since this should only
            // run when enabled is true
            apiKeyId: selectedApiKey!.id
          }
        })
      ).data.permissions,
    enabled: !!selectedApiKey
  });

  const [allowedParam, setAllowedParam] = useState<Record<string, boolean>>({});

  useEffect(() => {
    if (initialPermissionData) {
      setAllowedParam(
        initialPermissionData.reduce((acc: Record<string, boolean>, cur) => {
          acc[cur.parameterId] = true;
          return acc;
        }, {})
      );
    }
  }, [initialPermissionData]);

  const [locationValue, setLocationValue] = useState(0);
  const [divisionValue, setDivisionValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const ratio = useViewportRatio(1440);

  if (!selectedApiKey) return <div>No api selected</div>;
  if (permIsLoading || paramLoading) return <div>loading...</div>;
  if (permError) return <div>Error: cannot fetch permissions</div>;
  if (paramError) return <div>Error: cannot fetch parameter info</div>;

  const anchorPosition = {
    top: 500,
    left: 400 * ratio
  };

  const handleParamChange = (event: ChangeEvent<HTMLInputElement>) => {
    const id = event.target.name;
    console.log(id);
    if (event.target.checked) {
      setAllowedParam((prev) => {
        return { ...prev, [id]: true };
      });
    } else {
      setAllowedParam((prev) => {
        return { ...prev, [id]: false };
      });
    }
  };

  const handleApiUpdate: MouseEventHandler<HTMLButtonElement> = async () => {
    //console.log(allowedParam)
    const permissions = [];
    for (const paramId in allowedParam) {
      if (allowedParam[paramId]) {
        permissions.push({ parameterId: paramId });
      }
    }
    try {
      await api.patch('/admin/apikey', {
        apiKeyId: selectedApiKey.id,
        name: selectedApiKey.name,
        isActive: selectedApiKey.isActive,
        permissions: permissions
      });
      await refetch();
      alert('Api key updated');
      setSelectedApiKey(null);
      onClose();
    } catch (error: any) {
      alert(`Error message: ${error?.response?.data?.message}`);
    }
  };

  const handleLocationChange = (_: React.SyntheticEvent, newValue: number) => {
    setLocationValue(newValue);
    setDivisionValue(0);
  };
  const handleDivisionChange = (_: React.SyntheticEvent, newValue: number) => {
    setDivisionValue(newValue);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const getTabsAndTabPanels = () => {
    if (!allParam!.length) return null;
    const locBasedParamsWPerms = _.groupBy(allParam, 'location');
    let prefilteredStructuredParamList: StructuredParamsList = {};
    for (const location in locBasedParamsWPerms) {
      prefilteredStructuredParamList[location] = {
        LIVE: _.groupBy(
          locBasedParamsWPerms[location].filter(
            (obj) =>
              obj.category !== 'MONTHLY_CRUISE_PARAMETERS' && !obj.isMonthlyAvg
          ),
          'category'
        ),
        MONTHLY: _.groupBy(
          locBasedParamsWPerms[location].filter(
            (obj) =>
              obj.category !== 'MONTHLY_CRUISE_PARAMETERS' && obj.isMonthlyAvg
          ),
          'category'
        ),
        CRUISE: _.groupBy(
          locBasedParamsWPerms[location].filter(
            (obj) => obj.category == 'MONTHLY_CRUISE_PARAMETERS'
          ),
          'category'
        )
      };
    }

    // To maintain location order in the UI
    let structuredParamList: StructuredParamsList = {};
    Object.keys(CONSTANTS.LOCATION_ACRONYM_TO_FULL_FORM_MAP ?? {}).map(
      (location: string) => {
        if (prefilteredStructuredParamList[location]) {
          structuredParamList[location] =
            prefilteredStructuredParamList[location];
        }
      }
    );

    return (
      <>
        <Tabs value={locationValue} onChange={handleLocationChange}>
          {Object.keys(structuredParamList).map((location, idx) => (
            <Tab
              label={CONSTANTS.LOCATION_ACRONYM_TO_FULL_FORM_MAP?.[location]}
              key={`Tab-${idx}`}
            />
          ))}
        </Tabs>
        {Object.values(structuredParamList).map((sData, idx) => (
          <TabPanel
            value={locationValue}
            index={idx}
            key={`TabPanel-${idx}`}
            setDirection={true}
          >
            <Tabs value={divisionValue} onChange={handleDivisionChange}>
              {Object.keys(sData).map((divided: any, sIdx: any) => (
                <Tab
                  label={
                    divided == 'LIVE'
                      ? 'Sensor Live'
                      : divided == 'MONTHLY'
                        ? 'Monthly Averages'
                        : 'Cruise Data'
                  }
                  key={`Tab-${idx}-${sIdx}`}
                />
              ))}
            </Tabs>
            {Object.values(sData).map((pData, pIdx) => (
              <TabPanel
                value={divisionValue}
                index={pIdx}
                key={`TabPanel-${idx}-${pIdx}`}
              >
                {PARAMETER_TYPES.map((type: any, index: number) =>
                  pData[type] ? (
                    <div
                      key={`type-${idx}-${pIdx}-${index}`}
                      style={{ marginRight: 10 }}
                    >
                      <Typography
                        variant='assistive_text'
                        color='gray.700'
                        style={{
                          ...(type == 'WATER_CONTENTS' && {
                            visibility: 'hidden'
                          })
                        }}
                      >
                        {type.replace(/_/g, ' ')}
                      </Typography>
                      <div style={{ height: 16 }} />
                      {pData[type].map((param, index: number) => (
                        <div key={`param-${idx}-${pIdx}-${index}`}>
                          <FormControlLabel
                            key={param.id}
                            control={
                              <Checkbox
                                color='default'
                                checked={!!allowedParam[param.id]}
                                onChange={handleParamChange}
                                name={param.id}
                                disabled={param.isFree}
                              />
                            }
                            label={param.parameterDesc}
                          />
                        </div>
                      ))}
                    </div>
                  ) : null
                )}
              </TabPanel>
            ))}
          </TabPanel>
        ))}
      </>
    );
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <div style={modalContainerStyle} className='user-details'>
          <Typography
            variant='h2'
            color='blue.900'
            style={{ marginBottom: 15 }}
          >
            Api key Details:
          </Typography>
          <div>
            Id: <span>{selectedApiKey.id}</span>
          </div>
          <div>
            Name: <span>{selectedApiKey.name}</span>
          </div>
          <div>
            Is active:
            <input
              type='checkbox'
              checked={selectedApiKey.isActive}
              onChange={() => {
                setSelectedApiKey((prev) =>
                  prev ? { ...prev, isActive: !prev?.isActive } : null
                );
              }}
              style={{ marginRight: 8 }}
            />
            <span>{selectedApiKey.isActive ? 'True' : 'False'}</span>
          </div>
          <div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              Quantities:{' '}
              <Button
                sx={{
                  marginLeft: '30px'
                }}
                variant='primary'
                color='light'
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                }}
              >
                Open
              </Button>
            </div>
            <Popover
              open={Boolean(anchorEl)}
              anchorReference={anchorPosition ? 'anchorPosition' : 'anchorEl'}
              anchorPosition={anchorPosition}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right'
              }}
              sx={{
                '& .MuiPopover-paper': {
                  boxShadow: `0px 8px 10px rgba(0, 0, 0, 0.16)`,
                  borderRadius: '16px',
                  backgroundColor: 'white.50'
                }
              }}
            >
              <div style={{ padding: '26px 16px 16px 16px' }}>
                <IconButton
                  size='large'
                  style={{ float: 'right' }}
                  onClick={handlePopoverClose}
                >
                  <CloseIcon sx={{ color: 'blue.800' }} />
                </IconButton>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'column'
                  }}
                >
                  {getTabsAndTabPanels()}
                </div>
              </div>
            </Popover>
          </div>
          <div
            style={{
              display: 'flex',
              margin: 20
            }}
          >
            <Button
              type='button'
              variant='secondary'
              color='dark'
              onClick={() => {
                onClose();
              }}
              style={{ marginRight: 15 }}
            >
              Close
            </Button>
            <Button
              type='button'
              variant='primary'
              color='dark'
              onClick={handleApiUpdate}
            >
              Update
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ApiKeyDetailsModal;
