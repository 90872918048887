import Box from '@mui/material/Box';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useSetRecoilState } from 'recoil';

import CustomContainer from 'components/CustomContainer';
import {
  selectedApiState,
  editApiModalOpenState,
  ApiKey,
  useApiKeys
} from './state';
import { useMemo } from 'react';

const customStyles = {
  headRow: {
    style: {
      color: 'white',
      backgroundColor: '#6699CC' //blue
    }
  },
  headCells: {
    style: {
      fontSize: '16px',
      fontWeight: 'bold',
      border: '1px solid #ddd', // add border to header cells
      justifyContent: 'center'
    }
  },
  rows: {
    style: {
      minHeight: '56px',
      '&:nth-child(even)': {
        backgroundColor: '#FFFFFF' //white
      },
      '&:nth-child(odd)': {
        backgroundColor: '#D0D5E8' //grey
      },
      border: '1px solid #ddd' // add border to rows
    }
  },
  cells: {
    style: {
      fontSize: '14px',
      paddingLeft: '8px',
      paddingRight: '8px',
      width: 'min-content',
      borderRight: '1px solid #ddd', // add right border to cells
      justifyContent: 'center'
    }
  }
};

// =============================================================================
export default function ApisTable() {
  const setApiKeyId = useSetRecoilState(selectedApiState);
  const setEditModal = useSetRecoilState(editApiModalOpenState);
  const { data: apiKeys, isLoading } = useApiKeys();
  const columns = useMemo<TableColumn<ApiKey>[]>(
    () => [
      {
        cell: (e: ApiKey) => (
          <>
            <button
              onClick={() => {
                setApiKeyId(e);
                setEditModal(true);
              }}
              style={{ marginRight: 4 }}
            >
              Edit
            </button>
          </>
        ),
        width: '150px'
      },
      {
        name: 'ID',
        selector: (apiKey: ApiKey) => apiKey.id,
        sortable: true,
        width: '100px'
      },
      {
        name: 'Name',
        selector: (apiKey: ApiKey) => apiKey.name,
        sortable: true,
        width: '250px'
      },
      {
        name: 'Is active',
        selector: (apiKey: ApiKey) => (apiKey.isActive ? 'True' : 'False'),
        sortable: true
      }
    ],
    []
  );

  return (
    <CustomContainer>
      <div style={{ height: 32 }} />
      <Box sx={{ width: '100%' }}>
        <DataTable
          title=''
          columns={columns}
          data={apiKeys?.length ? apiKeys : []}
          progressPending={isLoading}
          pagination
          customStyles={customStyles}
          paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
        />
      </Box>
    </CustomContainer>
  );
}
