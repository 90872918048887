import { atom } from 'recoil';
import moment from 'moment';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { useConstants } from 'utils/hooks';

export const startAtom = atom({
  key: 'start',
  default: moment().subtract(2, 'weeks').startOf('day').unix() * 1000
});

export const endAtom = atom({
  key: 'end',
  default: moment().endOf('day').unix() * 1000
});

export const startQcAtom = atom({
  key: 'startQc',
  default: {
    i: null,
    ts: null
  }
});

export const endQcAtom = atom({
  key: 'endQc',
  default: {
    i: null,
    ts: null
  }
});

export const activeGraphAtom = atom({
  key: 'activeGraph',
  default: 'Line Chart'
});

export const isLoadingAtom = atom({
  key: 'isLoading',
  default: false
});

export const graphDataAtom = atom({
  key: 'graphData',
  default: []
});

export const boxDataAtom = atom({
  key: 'boxData',
  default: []
});

export const cardSetAtom = atom({
  key: 'cardSet',
  default: []
});

export const paramsAtom = atom({
  key: 'params',
  default: {}
});

export const useSetParams = () => {
  const setParams = useSetRecoilState(paramsAtom);
  return (data: any) => {
    setParams({ data: data });
  };
};

export const useAddCard = () => {
  const [cardSet, setCardSet]: [any, any] = useRecoilState(cardSetAtom);
  return (data: any) => {
    setCardSet([...cardSet, data]);
  };
};

export const useDeleteCard = () => {
  const [cardSet, setCardSet]: [any, any] = useRecoilState(cardSetAtom);
  return (key: any) => {
    const newCardSet = [...cardSet];
    newCardSet.splice(key, 1);
    setCardSet(newCardSet);
  };
};

export const useUpdateCard = () => {
  const [cardSet, setCardSet]: [any, any] = useRecoilState(cardSetAtom);
  return (index: any, data: any) => {
    const newCardSet = [...cardSet];
    newCardSet[index] = {
      ...newCardSet[index],
      ...data
    };

    setCardSet(newCardSet);
  };
};

export const useUpdateCardLocation = () => {
  const { CONSTANTS } = useConstants();
  const [cardSet, setCardSet]: [any, any] = useRecoilState(cardSetAtom);
  return (index: any, newLocation: any) => {
    const newCardSet = [...cardSet];
    newCardSet[index].location =
      CONSTANTS.DEPTH_ACRONYM_TO_FULL_FORM_MAP[newLocation];

    setCardSet(newCardSet);
  };
};

export const useUpdateCardDepth = () => {
  const { CONSTANTS } = useConstants();
  const [cardSet, setCardSet]: [any, any] = useRecoilState(cardSetAtom);
  return (index: any, newDepth: any) => {
    const newCardSet = [...cardSet];
    newCardSet[index] = {
      ...newCardSet[index],
      depth: {
        value: newDepth,
        text: CONSTANTS.DEPTH_ACRONYM_TO_FULL_FORM_MAP[newDepth]
      }
    };

    setCardSet(newCardSet);
  };
};

export const useDeleteDataset = () => {
  const [graphData, setGraphData] = useRecoilState(graphDataAtom);
  const [boxData, setBoxData] = useRecoilState(boxDataAtom);
  return (index: any) => {
    const newGraphData = [...graphData];
    newGraphData.splice(index, 1);
    setGraphData(newGraphData);
    const newBoxData = [...boxData];
    newBoxData.splice(index, 1);
    setBoxData(newBoxData);
  };
};
